import { set } from '@/utils/vuex'

export default {
  setGlobalEventFilter: set('globalEventFilter'),
  setGlobalYearFilter: set('globalYearFilter'),
  setGlobalSelectedEvent: set('globalSelectedEvent'),

  setAllFetchedEvents: set('allFetchedEvents'),
  setFilterStallHolders: set('filterStallHolders'),
  setFilterVouchers: set('filterVouchers'),
  setFilterStalls: set('filterStalls'),
  setFilterTerminalUser: set('filterTerminalUsers'),
  setFilterTerminals: set('filterTerminals'),
  setFilterYear: set('filterYear'),
  
  setSearchStartTime: set('searchStartTime'),
  setSearchEndTime: set('searchEndTime'),
  setSearchStallholders: set('searchStallholders'),
  setSearchStalls: set('searchStalls'),

  setAllYears: set('allYears'),
  setAllEvents: set('allEvents'),
  setAllTotalEvents: set('allTotalEvents'),
  setEOD: set('EOD'),
  IsAdmin: set('IsAdmin'),
  setAllStallHolders: set('allStallHolders'),
  setAllVouchers: set('allVouchers'),
  setAllStalls: set('allStalls'),
  setAllTerminalUsers: set('allTerminalUsers'),
  setAllTerminals: set('allTerminals')
}
