import { dashboardAPI } from '@/services'

export default {
  
  getGroups() {
    const api = dashboardAPI.getAdminGroupsEventsAPI()
    return api.getGroups().then(response => {
      return response
    })
  },
  
  getEvents() {
    const api = dashboardAPI.getAdminGroupsEventsAPI()
    return api.getEvents().then(response => {
      return response
    })
  },

  getProducts(context, payload) {
    const api = dashboardAPI.getAdminGroupsEventsAPI()
    return api.getProducts(payload).then(response => {
      return response
    })
  },

  // getLayouts(context, payload) {
  //   const api = dashboardAPI.getAdminGroupsEventsAPI()
  //   return api.getLayouts(payload).then(response => {
  //     return response
  //   })
  // },

  getLayouts(context, payload) {
    // console.log('Starting /admin/actions.js getLayouts')
    // console.log('getLayouts() payload',payload)
    const api = dashboardAPI.getLayoutsAPI()
    return api.getLayouts(payload).then(response => {
      return response
    })
  },

  postLayouts(context, payload) {
    const api = dashboardAPI.postLayoutsAPI()
    return api.postLayouts(payload).then(response => {
      return response
    })
  },

  getDevices(context, payload) {
    const api = dashboardAPI.getDevicesAPI()
    return api.getDevices(payload).then(response => {
      return response
    })
  },

  postDevices(context, payload) {
    const api = dashboardAPI.postDevicesAPI()
    return api.postDevices(payload).then(response => {
      return response
    })
  },

  createColour(context, payload) {
    const api = dashboardAPI.getAdminGroupsEventsAPI()
    return api.createColour(payload).then(response => {
      return response
    })
  },

  getColours() {
    const api = dashboardAPI.getAdminGroupsEventsAPI()
    return api.getColours().then(response => {
      return response
    })
  },

  getPortalUsers() {
    const api = dashboardAPI.getAdminGroupsEventsAPI()
    return api.getPortalUsers().then(response => {
      return response
    })
  },

  createPortalUsers(context, payload) {
    const api = dashboardAPI.getAdminGroupsEventsAPI()
    return api.createPortalUsers(payload).then(response => {
      return response
    })
  },

  getRecentevent(context, payload) {
    const api = dashboardAPI.getAdminGroupsEventsAPI()
    return api.getRecentevent(payload).then(response => {
      return response
    })
  },

  getEventByYear(context, payload) {
    const api = dashboardAPI.getAdminGroupsEventsAPI()
    return api.getEventsByYear(payload).then(response => {
      return response
    })
  },

  getEventMetadata(context, payload) {
    const api = dashboardAPI.getAdminGroupsEventsAPI()
    return api.getEventMetadata(payload).then(response => {
      return response
    })
  },
  
  createEvent(context, payload) {
    const api = dashboardAPI.getAdminGroupsEventsAPI()
    return api.createEvent(payload).then(response => {
      return response
    })
  },

  createCategories(context, payload) {
    const api = dashboardAPI.getAdminGroupsEventsAPI()
    return api.createCategories(payload).then(response => {
      return response
    })
  },

// DELETE ???
  getEventManagement(context, payload) {
    console.log("context")
    console.log(context)
    console.log("payload")
    console.log(payload)
    // const api = dashboardAPI.getAdminGroupsEventsAPI()
    // return api.getEventById(payload).then(response => {
    //   return response
    // })
  }
}
