import { dashboardAPI } from '@/services'

export default {
    initialLogin(context) {
      var loginAPI = dashboardAPI.getLoginAPI()
      var loginContext = {
        user: context.rootState.app.userInfo.username,
        accessToken: context.rootState.app.userInfo.signInUserSession.accessToken.jwtToken
      }

      localStorage.setItem('accessToken', context.rootState.app.userInfo.signInUserSession.accessToken.jwtToken)
      localStorage.setItem('accessUser', context.rootState.app.userInfo.username)
  
      return loginAPI.getAllData(loginContext).then(response => {
          context.commit('setEOD', response.EOD)
          context.commit('IsAdmin', response.IsAdmin)

          var recentlyViewedYear = response.RecentlyViewed.Year
          var recentlyViewedEvent = response.RecentlyViewed.EventId
          var recentlyViewedStartDate = response.RecentlyViewed.startDateTime
          var recentlyViewedEndDate = response.RecentlyViewed.endDateTime

          context.commit('setSearchStartTime', recentlyViewedStartDate)
          context.commit('setSearchEndTime', recentlyViewedEndDate)

          var yearObject = response.Events.find(x => x.Year == recentlyViewedYear)
          var eventObject = response.Events.find(x => x.EventId == recentlyViewedEvent)

          // context.commit('setGlobalSelectedEvent', response.RecentlyViewed)
          context.commit('setFilterYear', yearObject.Year)
          context.commit('setAllEvents', response.Events)
          context.commit('setAllTotalEvents', response.Events)
          context.commit('setAllFetchedEvents', response.Events)
          context.commit('setGlobalEventFilter', eventObject)
      
          context.commit('setAllStallHolders', eventObject.Stallholders)
          context.commit('setAllVouchers', eventObject.Vouchers)
          context.dispatch('extractAllStalls', eventObject.Stallholders)

        return response
      })
    },
    extractAllStalls(context, stallHolders) {
      var listOfStalls = stallHolders.map(stallHolder => stallHolder.Stalls )
      var listOfTerminalUsers = stallHolders.map(stallHolder => stallHolder.TerminalUsers )

      listOfStalls = listOfStalls.flat()
      listOfTerminalUsers = listOfTerminalUsers.flat()

      context.commit('setAllStalls', listOfStalls)
      context.commit('setAllTerminalUsers', listOfTerminalUsers)

      context.commit('setFilterStalls', listOfStalls)
      context.commit('setFilterTerminalUser', listOfTerminalUsers)

      context.dispatch('extractAllTerminals', listOfStalls)
    },
    extractAllTerminals(context, stalls) {
      var listOfTerminals = stalls.map(stall => stall.Terminals )
      listOfTerminals = listOfTerminals.flat()

      listOfTerminals = listOfTerminals.filter(element => {
        return element !== undefined;
      });

      context.commit('setAllTerminals', listOfTerminals)
      context.commit('setFilterTerminals', listOfTerminals)
    }
}