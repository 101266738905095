<template>
  <v-container fluid>
    <v-row>
        <v-col cols="12" md="12">
            <v-card dark outlined>
            <v-card-title class="secondary py-1 text-subtitle-1">
                <v-icon left dense>mdi-filter</v-icon>
                {{header}} Filters
                <v-spacer></v-spacer>
                <v-btn 
                elevation="2" 
                class="mr-2" 
                x-small
                @click="getProductsReport"
                >Product Report
              </v-btn>
            </v-card-title>

            <v-card-text> 
               <v-row>
                 <v-col cols="12" md="2">
                    <v-datetime-picker
                        v-model="startDate"
                        :text-field-props="textFieldPropsStartDate"
                        :date-picker-props="datePropsStartDate"
                        :time-picker-props="timePropsStartDate"
                        date-format="dd/MM/yyyy"
                        @input="startDateTimeChanged"
                    ></v-datetime-picker>
                    <div>
                  Event Start:
                  <br />
                  {{ formattedStartTime }}
                  <br />
                  {{ formattedStartDate }}
                </div>
                 </v-col>
                 <v-col cols="12" md="2">
                    <v-datetime-picker
                        v-model="endDate"
                        :text-field-props="textFieldPropsEndDate"
                        :date-picker-props="datePropsEndDate"
                        :time-picker-props="timePropsEndDate"
                        date-format="dd/MM/yyyy"
                        @input="endDateTimeChanged"
                    ></v-datetime-picker>
                    <div>
                  Event End:
                  <br />
                  {{ formattedEndTime }}
                  <br />
                  {{ formattedEndDate }}
                </div>
                 </v-col>

                 <v-col cols="12" md="2">
                    <global-MultipleSelect 
                    ref="stallHoldersRef" 
                    :label="labelStallHolders" 
                    :dataList="allStallHolders" 
                    :itemText="StallHolderName" 
                    :itemValue="StallHolderId" 
                    @CheckVisibility="haddleCheckVisibility" 
                    @checkSelectedList="haddleSelectedList">
                    </global-MultipleSelect>
                 </v-col>
                 <v-col cols="12" md="2">
                    <global-MultipleSelect 
                    ref="stallRef" 
                    :label="labelStall" 
                    :dataList="stalls" 
                    :itemText="StallName" 
                    :itemValue="StallId" 
                    :isDisable="isDisableStall">
                    </global-MultipleSelect>
                 </v-col>

                 <v-col cols="12" md="4" class="text-right">
                <v-btn 
                color="secondary"
                class="mt-4 white--text mr-4 d-inline-flex"
                @click="handleRefresh">
                  <v-icon dark class="mx-auto">mdi-refresh</v-icon>
                </v-btn>
                   <v-btn
                        color="secondary"
                        class="mt-4 white--text"
                        @click="handleClickSubmit"
                    >
                        Submit
                        <v-icon right dark>mdi-magnify</v-icon>
                    </v-btn>
                 </v-col>
                </v-row>
            </v-card-text>
            </v-card>
        </v-col>
    </v-row>
  </v-container>
 </template>
 
 
<script>
import moment from 'moment'
import {
  mapState,
  mapMutations,
  mapActions
} from 'vuex'
import 'izitoast/dist/css/iziToast.min.css';
import iziToast from 'izitoast/dist/js/iziToast.min.js';

export default {
  props: {
    header: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      presignedurl: '',
      allStallHoldersList: [],
      productsReportItems: {},
      productsReport:{
        reportType: ''        
      },

      startDate: new Date(),

      textFieldPropsStartDate: {
        appendIcon: 'event'
      },
      datePropsStartDate: {
        headerColor: 'black'
      },
      timePropsStartDate: {
        useSeconds: false,
        ampmInTitle: true
      },
      endDate: new Date(),

      textFieldPropsEndDate: {
        appendIcon: 'event'
      },
      datePropsEndDate: {
        headerColor: 'black'
      },
      timePropsEndDate: {
        useSeconds: false,
        ampmInTitle: true
      },

      labelStallHolders: 'Stallholders',
      StallHolderName: 'StallholderName',
      StallHolderId: 'StallholderId',

      labelStall: 'Stall',
      StallName: 'StallName',
      StallId: 'StallId',

      selectedStallHolders: [],
      stalls: [],
      selectedStall: [],

      isDisableStall: true,

      QueryStrings: {},
      startDateTimeSet: false,
      endDateTimeSet: false,
      formattedStartTime: '',
      formattedStartDate: '',
      formattedEndTime: '',
      formattedEndDate: '',
      
    }
  },
  created() {
      this.startDate = this.searchStartTime != 'notSet'  ? moment(this.searchStartTime, 'YYYYMMDDHHmm').toDate() : new Date();
      this.endDate = this.searchEndTime != 'notSet'  ? moment(this.searchEndTime, 'YYYYMMDDHHmm').toDate() : new Date();

      this.fetchStallHolders();
    if (this.globalEventFilter.EventId) {
      // const eventObject = this.allFetchedEvents.find(event => event.EventId === this.globalEventFilter.EventId);
      // if (eventObject) {
        this.formattedStartTime = moment(this.globalEventFilter.startDateTime, 'YYYYMMDDHHmm').format('h:mm A');
        this.formattedStartDate = moment(this.globalEventFilter.startDateTime, 'YYYYMMDDHHmm').format('DD MMM YY');
        this.formattedEndTime = moment(this.globalEventFilter.endDateTime, 'YYYYMMDDHHmm').format('h:mm A');
        this.formattedEndDate = moment(this.globalEventFilter.endDateTime, 'YYYYMMDDHHmm').format('DD MMM YY');
      // }
    }
  },
  computed: {
    ...mapState('filter', [
      'filterStallHolders',
      'filterStalls',
      'allStallHolders',
      'allStalls',
      'EOD',
      'globalEventFilter',
      'searchStartTime',
      'searchEndTime',
      'searchStallholders',
      'searchStalls',
      'globalEventFilter',
      // 'allEvents',
      // 'allFetchedEvents',
      // 'globalSelectedEvent'
    ]),
  },
  
  watch: {
    startDate(newVal) {
      if (newVal) {
        this.formattedStartTime = moment(newVal).format('HH:mm');
        this.formattedStartDate = moment(newVal).format('DD/MM/YYYY');
      }
    },
    endDate(newVal) {
      if (newVal) {
        this.formattedEndTime = moment(newVal).format('HH:mm');
        this.formattedEndDate = moment(newVal).format('DD/MM/YYYY');
      }
    },
    'globalEventFilter.EventId'(newVal) {
      if (newVal) {
        // const eventObject = this.allFetchedEvents.find(event => event.EventId === newVal);
        // if (eventObject) {
          this.formattedStartTime = moment(this.globalEventFilter.startDateTime, 'YYYYMMDDHHmm').format('h:mm A');
          this.formattedStartDate = moment(this.globalEventFilter.startDateTime, 'YYYYMMDDHHmm').format('DD MMM YY');
          this.formattedEndTime = moment(this.globalEventFilter.endDateTime, 'YYYYMMDDHHmm').format('h:mm A');
          this.formattedEndDate = moment(this.globalEventFilter.endDateTime, 'YYYYMMDDHHmm').format('DD MMM YY');
        // }
      }
      this.setSearchStartTime('notSet');
      this.setSearchEndTime('notSet');
      this.startDateTimeSet = false;
      this.endDateTimeSet = false;
     

      // Fetch stallholders when EventId changes
      this.fetchStallHolders();
      this.updateDashboard();
    },
  },

  methods: {
    ...mapMutations('filter', ['setSearchStartTime', 'setSearchEndTime', 'setSearchStallholders', 'setSearchStalls']),
    ...mapActions('filter', ['extractAllStalls']),
    ...mapActions('dashboard', ['getReports', 'updateDashboard']),

    getProductsReport: function () {
      console.log('Starting getProductsReport with this.getProductsReportItems ', this.productsReportItems)
      
      if (this.searchStartTime != 'notSet') {
        this.productsReportItems.startTime = this.searchStartTime;
      }       
      if (this.searchEndTime != 'notSet') {
        this.productsReportItems.endTime = this.searchEndTime;
      }
      this.productsReportItems.stallholders = this.searchStallholders
      this.productsReportItems.stalls = this.searchStalls
      this.productsReportItems.eventId = this.globalEventFilter.EventId
      this.productsReport.reportType = 'productsReport'
      this.productsReport.requestItems = this.productsReportItems

      iziToast.success({
            title: 'Products Report',
            message: "Generating Product Report, standby",
            position: 'topRight'
        });
        
      console.log('getProductsReport this.productsReport: ',this.productsReport)

      this.getReports(this.productsReport).then(response => {
        console.log('getProductsReport Response: ',response)
        this.presignedurl = response.S3presignedurl
        window.open(this.presignedurl,"_self")
      }).catch(err => {
        console.log("err")
        console.log(err)

        iziToast.warning({
            title: 'Products Report',
            message: 'Error getting Product Report, please contact TSG',
            position: 'center'
        });
      })
      
    },
    fetchStallHolders() {
      // const eventObject = this.globalSelectedEvent.find(event => event.EventId === eventId);
      if (this.globalEventFilter.StallHolders) {
        this.allStallHoldersList = this.globalEventFilter.Stallholders;
      } else {
        this.allStallHoldersList = [];
      }
      this.globalEventFilter.Stallholders.forEach((obj) => {
        
            this.allStallHoldersList.push(obj)
        });
      // console.log('==----- Event: ', eventObject); 
      // console.log('==----- EventObject Stall Holders : ', eventObject.Stallholders); 
      // console.log('==-----Stall Holders: ', this.allStallHoldersList); 
    },

    haddleCheckVisibility: function () {
      if (this.$refs.stallHoldersRef.selectedList.length === 0) {
        this.isDisableStall = true;
      } else {
        this.isDisableStall = false;
      }
    },

    haddleSelectedList: function (value) {
      if (value.length > 0) {
        this.stalls = [];
        value.forEach((obj) => {
            obj.Stalls.forEach((data) => {
              this.stalls.push(data)
            });
        });
      }
    },
    handleRefresh: function(){
      this.updateDashboard()
    },

    dateTimeEOD(value) {
      return moment(value, "HH:mm").format('HH:mm A');
    },

    startDateTimeChanged: function () {
      this.startDateTimeSet = true
    },

    endDateTimeChanged: function () {
      this.endDateTimeSet = true
    },

    handleClickSubmit: function () {

      let stallHolder = this.$refs.stallHoldersRef.selectedList;
      let stall = this.$refs.stallRef.selectedList;
        
      this.selectedStallHolders = stallHolder.map(r => {
        this.StallHolderId = r.StallholderId
        return r.StallholderId;
      });

      this.selectedStall = stall.map(r => {
        this.StallId = r.StallId;
        return r.StallId;
      });
      
      this.QueryStrings.stallholders = this.selectedStallHolders
      this.QueryStrings.stalls = this.selectedStall
      
      if (this.QueryStrings.stallholders == '') {
          this.QueryStrings.stallholders = 'notSet';
      }

      if (this.QueryStrings.stalls == '') {
          this.QueryStrings.stalls = 'notSet';
      }

      this.setSearchStallholders(this.QueryStrings.stallholders)
      this.setSearchStalls(this.QueryStrings.stalls)

      if (this.startDateTimeSet == true) {      
        let sDate = moment(this.startDate).format('YYYYMMDDHHmm');
        this.QueryStrings.startTime = sDate;
        } else {
          this.QueryStrings.startTime = 'notSet';
      }

      if (this.endDateTimeSet == true) {      
      let eDate = moment(this.endDate).format('YYYYMMDDHHmm');
      this.QueryStrings.endTime = eDate;
      } else {
        this.QueryStrings.endTime = 'notSet';
      }

      if (this.QueryStrings.startTime == 'Invalid date') {
          this.QueryStrings.startTime = 'notSet';
      }

      if (this.QueryStrings.endTime == 'Invalid date') {
          this.QueryStrings.endTime = 'notSet';
      }

      this.setSearchStartTime(this.QueryStrings.startTime)
      this.setSearchEndTime(this.QueryStrings.endTime)
      
      this.updateDashboard()

    }
  }
}
</script>
<style scoped>
</style>
