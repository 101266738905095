import { dashboardAPI } from '@/services'

function populateComponent(APICall, context, setStateData, setStateLoading, queryParamsContext ) {
  APICall(queryParamsContext).then(response => {
    context.commit(setStateData, response)
    context.commit(setStateLoading, false)
    console.log(setStateData, response)
  }).catch(err => {
    console.log(`${setStateData.toUpperCase()} component API error`, err)
    // Attempt to refresh token if that was the error (this may not be the right place)
  })
}

export default {
  initialLogin(context) {
    var loginAPI = dashboardAPI.getLoginAPI()
    var loginContext = {
      user: context.rootState.app.userInfo.username,
      accessToken: context.rootState.app.userInfo.signInUserSession.accessToken.jwtToken
    }

    return loginAPI.getAllData(loginContext).then(response => {

    return response
    })
  },
  updateDashboard(context) {
    var selectedEvent = context.rootState.filter.globalEventFilter
    var eventId = selectedEvent.EventId

    var searchStartTime = context.rootState.filter.searchStartTime
    var searchEndTime = context.rootState.filter.searchEndTime
    var searchStallholders = context.rootState.filter.searchStallholders
    var searchStalls = context.rootState.filter.searchStalls

    var allStalls = context.rootState.filter.allStalls

    var stallHolderFilters = context.rootState.filter.filterStallHolders
    var stallFilters = context.rootState.filter.filterStalls

    // Home page
    var homeAPI = dashboardAPI.getHomeAPI()
    var homeContext = {
      eventId: eventId,
      searchStartTime: searchStartTime,
      searchEndTime:  searchEndTime
    }
    populateComponent(homeAPI.getTotals.bind(homeAPI), context, 'setHomeTotals', 'setHomeTotalsLoading', homeContext)
   
    // Product Sales
    var productSalesAPI = dashboardAPI.getProductSalesAPI()
    var productSalesContext = {
      eventId: eventId,
      searchStartTime: searchStartTime,
      searchEndTime:  searchEndTime,
      searchStallholders: searchStallholders,
      searchStalls: searchStalls,
      
      stallholderStallFilter: stallFilters.length == allStalls.length && stallHolderFilters == 'all' ? 'all-all' : stallFilters.map(x => x.StallholderStall),
      stalls: stallFilters.map(x => x.StallholderStall)
    }
    
    populateComponent(productSalesAPI.getTotalTransactions.bind(productSalesAPI), 
      context, 'setProductSalesTotalTransactions', 'setProductSalesTotalTransactionsLoading', productSalesContext)
                      
    // Takings
    var takingsAPI = dashboardAPI.getTakingsAPI()

    var takingsContext = {
      eventId: eventId,
      searchStartTime: searchStartTime,
      searchEndTime:  searchEndTime,
      searchStallholders: searchStallholders,
      searchStalls: searchStalls,

      // If user selects all available stalls then it given that all stallholders are also selected
      stallholderStallFilter: stallFilters.length == allStalls.length && stallHolderFilters == 'all' ? 'all-all' : stallFilters.map(x => x.StallholderStall),
      stalls: stallFilters.map(x => x.StallholderStall)
    }
    populateComponent(takingsAPI.getTotalSales.bind(takingsAPI), 
      context, 'setTakingsTotalSales', 'setTakingsTotalSalesLoading', takingsContext) 
                      
    // populateComponent(takingsAPI.getStallComparison.bind(takingsAPI), 
    // context, 'setTakingsStallComparison', 'setTakingsStallComparisonLoading', takingsContext)
    
  },

  getTransactions(context) {
    dashboardAPI.getTransactionsAPI().getTransactions("TESTING transactions").then(response => {
      context.commit('setTransactionsTransactions', response)
    })
  },

  getReports(context, payload) {
    const api = dashboardAPI.getReportsAPI()
    return api.getReports(payload).then(response => {
      console.log('Starting actions.js getReports')
      return response
    })
  },

}