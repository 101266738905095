<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="12">
        <v-card dark outlined>
          <v-card-title class="secondary py-1 text-subtitle-1">
            <v-icon left dense>mdi-filter</v-icon>
            {{ header }} Filters
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col cols="12" md="2">
                <v-datetime-picker
                  v-model="startDate"
                  :text-field-props="textFieldPropsStartDate"
                  :date-picker-props="datePropsStartDate"
                  :time-picker-props="timePropsStartDate"
                  date-format="dd/MM/yyyy"
                  @input="startDateTimeChanged"
                ></v-datetime-picker>
                <!-- Display formatted start time and date -->
                <div>
                  Event Start:
                  <br />
                  {{ formattedStartTime }}
                  <br />
                  {{ formattedStartDate }}
                </div>
              </v-col>
              <v-col cols="12" md="2">
                <v-datetime-picker
                  v-model="endDate"
                  :text-field-props="textFieldPropsEndDate"
                  :date-picker-props="datePropsEndDate"
                  :time-picker-props="timePropsEndDate"
                  date-format="dd/MM/yyyy"
                  @input="endDateTimeChanged"
                ></v-datetime-picker>
                <!-- Display formatted end time and date -->
                <div>
                  Event End:
                  <br />
                  {{ formattedEndTime }}
                  <br />
                  {{ formattedEndDate }}
                </div>
              </v-col>

              <v-col cols="12" md="8" class="text-right">
                <v-btn
                  color="secondary"
                  class="mt-4 white--text mr-4 d-inline-flex"
                  @click="handleRefresh"
                >
                  <v-icon dark class="mx-auto">mdi-refresh</v-icon>
                </v-btn>
                <v-btn
                  color="secondary"
                  class="mt-4 white--text"
                  style="text-align: end;"
                  @click="handleClickSubmit"
                >
                  Submit
                  <v-icon right dark>mdi-magnify</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment';
import { mapState, mapActions, mapMutations } from 'vuex';

export default {
  props: {
    header: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      startDate: new Date(),
      textFieldPropsStartDate: {
        appendIcon: 'event',
      },
      datePropsStartDate: {
        headerColor: 'black',
      },
      timePropsStartDate: {
        useSeconds: false,
        ampmInTitle: true,
      },
      endDate: new Date(),
      textFieldPropsEndDate: {
        appendIcon: 'event',
      },
      datePropsEndDate: {
        headerColor: 'black',
      },
      timePropsEndDate: {
        useSeconds: false,
        ampmInTitle: true,
      },
      startDateTimeSet: false,
      endDateTimeSet: false,
      formattedStartTime: '',
      formattedStartDate: '',
      formattedEndTime: '',
      formattedEndDate: '',

      updatedEvent: {},
    };
  },
  created() {
  this.startDate =
    this.searchStartTime !== 'notSet' && this.searchStartTime
      ? moment(this.searchStartTime, 'YYYYMMDDHHmm').toDate()
      : null;
  this.endDate =
    this.searchEndTime !== 'notSet' && this.searchEndTime
      ? moment(this.searchEndTime, 'YYYYMMDDHHmm').toDate()
      : null;
  
  if (this.globalEventFilter.EventId) {
    // const eventObject = this.allFetchedEvents.find(event => event.EventId === this.globalEventFilter.EventId);
    // if (eventObject) {
      this.formattedStartTime = moment(this.globalEventFilter.startDateTime, 'YYYYMMDDHHmm').format('h:mm A');
      this.formattedStartDate = moment(this.globalEventFilter.startDateTime, 'YYYYMMDDHHmm').format('DD MMM YY');

      this.formattedEndTime = moment(this.globalEventFilter.endDateTime, 'YYYYMMDDHHmm').format('h:mm A');
      this.formattedEndDate = moment(this.globalEventFilter.endDateTime, 'YYYYMMDDHHmm').format('DD MMM YY');
    // }
  }
},
  computed: {
    ...mapState({
      searchStartTime: (state) => state.filter.searchStartTime,
      searchEndTime: (state) => state.filter.searchEndTime,
      globalEventFilter: (state) => state.filter.globalEventFilter,
      // allEvents: (state) => state.filter.allEvents,
      // allFetchedEvents: (state) => state.filter.allFetchedEvents,
      // globalSelectedEvent: (state) => state.filter.globalSelectedEvent
    }),
  },
  watch: {
    startDate(newVal) {
      if (newVal) {
        this.formattedStartTime = moment(newVal).format('HH:mm');
        this.formattedStartDate = moment(newVal).format('DD/MM/YYYY');
      }
    },
    endDate(newVal) {
      if (newVal) {
        this.formattedEndTime = moment(newVal).format('HH:mm');
        this.formattedEndDate = moment(newVal).format('DD/MM/YYYY');
      }
    },
    'globalEventFilter.EventId'(newVal) {
      if (newVal) {
        
        // const eventObject = this.allFetchedEvents.find(event => event.EventId === newVal);

        // if (eventObject) {
          this.formattedStartTime = moment(this.globalEventFilter.startDateTime, 'YYYYMMDDHHmm').format('h:mm A');
          this.formattedStartDate = moment(this.globalEventFilter.startDateTime, 'YYYYMMDDHHmm').format('DD MMM YY');

          this.formattedEndTime = moment(this.globalEventFilter.endDateTime, 'YYYYMMDDHHmm').format('h:mm A');
          this.formattedEndDate = moment(this.globalEventFilter.endDateTime, 'YYYYMMDDHHmm').format('DD MMM YY');
        // }
      
        this.startDate = null;
        this.endDate = null;

        this.setSearchStartTime('notSet');
        this.setSearchEndTime('notSet');
      
        // this.startDateTimeSet();
        // this.endDateTimeSet();
        
        this.updateDashboard();
      }
    },
  },
  methods: {
    ...mapActions('dashboard', ['updateDashboard']),
    ...mapActions('admin', ['getEventMetadata']),
    ...mapMutations('filter', [
      'setSearchStartTime',
      'setSearchEndTime',
      'setGlobalEventFilter'
    ]),
    startDateTimeChanged() {
      this.startDateTimeSet = true;
    },
    endDateTimeChanged() {
      this.endDateTimeSet = true;
    },
    handleRefresh() {
      this.updateDashboard();
    },
    handleClickSubmit() {
      if (this.startDateTimeSet) {
        let sDate = moment(this.startDate).format('YYYYMMDDHHmm');
        this.setSearchStartTime(sDate);
      } else {
        this.setSearchStartTime('notSet');
      }

      if (this.endDateTimeSet) {
        let eDate = moment(this.endDate).format('YYYYMMDDHHmm');
        this.setSearchEndTime(eDate);
      } else {
        this.setSearchEndTime('notSet');
      }

      this.updateDashboard();
    },
  },
};
</script>

<style scoped>
.v-card {
  margin-bottom: 20px;
}
</style>
